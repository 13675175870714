AOS.init({
    duration: 1000,
    easing: 'ease-in-sine',
    delay: 70,

});

$(function(){
    $('#textslider').slick({
        slidesToShow: 1,
		slidesToScroll: 1,
		autoplay: true,
		autoplaySpeed: 5850,
		dots: false,
		arrows: true,
		infinite: true,
		draggable: false,
		pauseOnHover: false,
		pauseOnFocus: false,
		swipeToSlide: false,
		touchMove: false,
		responsive: [{
				breakpoint: 1570,
				settings: {
					slidesToShow: 1,
                    slidesToScroll: 1,
				}
			},
			{
				breakpoint: 1050,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1
				}
			},
			{
				breakpoint: 800,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1
				}
			},
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1
				}
			}
		]
    });
  });


  var url = window.location.href;
  jQuery('.url').val(url);
  jQuery('body').addClass('no_scroll');



  
  $(".wpcf7-list-item").each(function () {
	if ($(this).find('input').prop('checked')) {
		$(this).find('.wpcf7-list-item-label').addClass("active");
	} else {
		$(this).find('.wpcf7-list-item-label').removeClass("active");

	}
});
$('.wpcf7-list-item-label').click(function () {

	setTimeout(() => {

		$(".wpcf7-list-item").each(function () {
			if ($(this).find('input').prop('checked')) {
				$(this).find('.wpcf7-list-item-label').addClass("active");
			} else {
				$(this).find('.wpcf7-list-item-label').removeClass("active");

			}
		});
	}, 20);
})